<template>
  <validation-observer ref="simpleRules">
    <b-form>
      <b-card>
        <b-card-title><h2 class="border-bottom pb-1">
          Create new blog
        </h2>
        </b-card-title>
        <br>
        <b-row>
          <!--  name-->
          <b-col md="12">
            <b-form-group>
              <label class="required">Title</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Title"
              >
                <b-form-input
                  v-model="blog.title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group>
              <label class="required">Content</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="content"
              >
                <quill-editor v-model="blog.content" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="language"
              rules="required"
            >
              <b-form-group :state="errors.length > 0 ? false : null">
                <label
                  class="required"
                  for="language"
                >Language</label>

                <v-select
                  id="language"
                  v-model="blog.language"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="blog.localeOption"
                  :clearable="true"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Featured Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <small
                    class="text-muted"
                  >Required image resolution 800x400, image size 10mb.</small>
                  <b-card-text class="my-50">
                    <b-link id="blog-image-text" />
                  </b-card-text>
                  <div class="d-inline-block">
                    <b-form-file
                      ref="refInputEl"
                      v-model="blog.blogFile"
                      accept=".jpg, .png, .gif"
                      placeholder="Choose file"
                      @input="inputImageRenderer"
                    />
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Cover Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewElCover"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <small
                    class="text-muted"
                  >Required image resolution 800x400, image size 10mb.</small>
                  <b-card-text class="my-50">
                    <b-link id="blog-image-text" />
                  </b-card-text>
                  <div class="d-inline-block">
                    <b-form-file
                      ref="refInputElCover"
                      v-model="blog.coverImage"
                      accept=".jpg, .png, .gif"
                      placeholder="Choose file"
                      @input="inputImageRenderer2"
                    />
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

          <!--
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="idea-icon"
                rules=""
              >
                <label class="required" for="idea-icon">
                  The main blog image</label
                >
                <b-form-file
                  v-model="blog.icon"
                  :state="errors.length > 0 ? false : null"
                  accept="image/jpeg, image/png, image/gif"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="idea-images"
                rules=""
              >
                <label class="" for="idea-images">Drop the cover image</label>
                <b-form-file
                  v-model="blog.images"
                  accept="image/jpeg, image/png, image/gif"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from 'vue-quill-editor'

import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { useInputImageRenderer2 } from '@core/comp-functions/forms/form-utils2'
import { ref } from '@vue/composition-api'

import { mapActions, mapGetters } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BFormInvalidFeedback,
  BAlert,
  BCardTitle,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BCardText,
} from 'bootstrap-vue'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  digits,
  alphaDash,
  length,
} from '@validations'

export default {
  components: {
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    BFormDatepicker,
    BFormTextarea,
    BFormInvalidFeedback,
    BCardTitle,
    ToastificationContent,
    BAlert,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BCardText,
    vSelect,
  },
  data() {
    return {
      blog: {
        title: '',
        content: '',
        language: '',
        blogFile: [],
        coverImage: [],
        icon: [],
        images: [],
        statusOption: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        localeOption: [
          { label: 'English', value: 'en' },
          { label: 'Arabic', value: 'ar' },
        ],
      },
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  setup() {
    const refInputEl = ref()
    const refPreviewEl = ref()
    const refInputElCover = ref()
    const refPreviewElCover = ref()

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRenderer2 } = useInputImageRenderer2(
      refInputElCover,
      base64 => {
        refPreviewElCover.value.src = base64
      },
    )

    return {
      refInputEl,
      refPreviewEl,
      refInputElCover,
      refPreviewElCover,
      inputImageRenderer,
      inputImageRenderer2,
    }
  },
  methods: {
    ...mapActions({
      createBlog: 'profile/createBlog',
    }),

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //   this.isLoading = true;

          const blogData = new FormData()
          blogData.append('title', this.blog.title)
          blogData.append('content', this.blog.content)
          blogData.append('language', this.blog.language.value)
          blogData.append('type', 'blog')

          blogData.append('postFiles', this.blog.blogFile)
          blogData.append('coverImage', this.blog.coverImage)
          //   for (const i of Object.keys(this.blog.images)) {
          //     blogData.append("postFiles", this.blog.images[i]);
          //   }

          //   axios
          //     .post("posts/", blogData)
          this.createBlog(blogData)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
              // this.$vs.loading.close();
              this.$router.push({ name: 'blogs' })
            })
            .catch(error => {
              this.errorMessage = error.response.data.error.message
              console.log(error)
            })
          //   this.isLoading = false;
        }
      })
    },
  },
}
</script>

<style type="text/css">
@charset "UTF-8";

[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}
[dir] .vs__selected {
  background-color: #366ce3;
}
.col-md-6 {
  margin-bottom: 5px;
}

.required:after {
  content: " *";
  color: red;
}

[dir] .ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 350px;
}
</style>
